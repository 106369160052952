<template>
  <div class="" >
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>

    <div class="p-sm-4  p-3" v-else>
      <DueCounter :order="order" />

      <div class=" is-order-card ">
        <div class="  " style="border-radius: 20px">
          <div class="row" v-if="Object.keys(order).length > 0">
            <div class="col-12 col-sm-6 col-md-8 text-left" sm="6">
              <h1
              class="order-id"
              >
                Order ID # {{ order.id || "" }}
              </h1>
              <p >
                <strong>{{ order.subject_area_text || "" }}</strong> |
                <strong>{{ order.academic_level_text || "" }}</strong> |
                <strong>{{ order.pages || "" }} page(s)</strong>
                <span
                class="
                status-order
                  mx-sm-2 mx-1 my-sm-0 my-2
                "
                >Status: {{ order.status_text || "" }}</span
              >
              </p>
              
            </div>

            <div class="col-12 col-sm-6 col-md-4" sm="6">
              <div
                class="m-0 order-listing-header rounded p-3 text-left"
                v-if="
                  order.writer &&
                  order.writer_id &&
                  userType != userTypes.writer
                "
              >
                <div class="d-flex flex-row align-items-center justify-content-start">
                  <div class="w-16 mr-2 h-16 rounded-full text-capitalize bg-slate-200 dark:bg-darkmode-400 flex items-center justify-center text-base font-medium">
                  W{{order.writer.writer_name.charAt(0) || ""}}
                  </div>
                  <div class="d-flex mx-2 flex-column">
                    <h4 class="font-weight-bold mb-0 text-left">
                      Writer:
                      <router-link
                        class="text-capitalize  "
                        :to="{
                          name: 'WriterProfile',
                          params: { id: order.writer_id },
                        }"
                      >
                        {{ order.writer.writer_name || "" }} (
                        {{ order.writer_id || "" }} )
                      </router-link>
                    </h4>
                  
                    <p class="is-rating-stars-1 text-right d-flex align-items-center justify-content-start mb-1">
                      <span v-for="(i, index) in 5" :key="index + 'rating'">
                        <span
                          class="text-warning icon"
                          :class="
                            order.writer.rating.rate >= index + 1
                              ? 'icon-star-full2'
                              : 'icon-star-empty3'
                          "
                        ></span>
                      </span>
                      <span style="font-size: 16px; font-weight: bold"
                        > ({{ order.writer.rating.rate || "0" }}) Stars
                      </span>
                    </p>
                    <p class="text-weight-bold text-success text-right  align-items-center justify-content-start  d-flex mb-1">
                    ({{ order.writer.rating.total_reviews || 0 }}) Reviews.
                    ({{ order.writer.completed_orders || 0 }}) Completed orders
                  </p>
                  </div>
                 
                </div>
                <!-- No of reviews -->
                

                <!-- Fine writer component -->
                  <FineComponent
                    :order_id="order.id || '-1'"
                    :writer_id="order.writer_id"
                  />
                <!-- Fine writer component -->
              </div>
            </div>
          </div>

          <ul class="nav nav-tabs text-center nav-tabs-bottom pl-0">
            <li class="nav-item">
              <a
                href="#info"
                class="nav-link px-sm-4 active show font-weight-bold text-uppercase"
                data-toggle="tab"
                >Order Info</a
              >
            </li>
            <li class="nav-item">
              <a
                href="#messages"
                class="nav-link px-sm-4 font-weight-bold text-uppercase"
                data-toggle="tab"
                >Messages
                <span class="badge badge-success badge-pill" v-if="order.no_messages > 0"> {{  order.no_messages  }} </span>
                </a
              >
            </li>
            <li class="nav-item">
              <a 
                href="#files"
                class="nav-link px-sm-4 font-weight-bold text-uppercase"
                data-toggle="tab"
                >Files
                <span class="badge badge-success badge-pill" v-if="order.no_files > 0"> {{  order.no_files  }} </span>
                </a
              >
            </li>
          </ul>

          <div class="tab-content  card p-4 my-3">
            <div class="tab-pane fade active show" id="info">
              <OrderInfo
                :order="order"
                v-if="
                  userType == userTypes.admin 
                "
              ></OrderInfo>



              <EditorOrderInfo
                :order="order"
                v-else-if="userType == userTypes.editor"
              ></EditorOrderInfo>
              <WriterOrderInfo :order="order" v-else></WriterOrderInfo>
            </div>

            <div class="tab-pane fade" id="messages">
              <OrderMessages></OrderMessages>
            </div>

            <div class="tab-pane fade" id="files">
              <OrderFiles></OrderFiles>
            </div>
          </div>
        </div>
      </div>

      <div  class=" ">
        <div
          class="card d-flex flex-row flex-wrap  p-4 my-4 text-left"
          v-if="order.buttons && order.buttons.length > 0"
        >
          <span v-for="(action, index) in order.buttons" :key="index + 'oa'">
            <span v-if="!!action">
              <router-link
                v-if="action.is_link"
                :to="{ name: action.action, params: { id: order.id } }"
                class="btn btn-md rounded-full m-1 text-light"
                :class="`bg-${action.color || 'primary'}`"
                >{{ action.title }}</router-link
              >
              <button
                v-else
                class="btn btn-md rounded-full  m-1"
                @click="order_action = action"
                :class="`bg-${action.color || 'primary'}`"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                {{ action.title }}
              </button>
            </span>
          </span>
        </div>
      </div>

      

      <UpdateOrderStatus :action="order_action" :writers="writers" ></UpdateOrderStatus>
    </div>

    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>

    <BaseModal :title="'Wallet Topup'" :modalId="'tipModal'">
      <TipWriter></TipWriter>
    </BaseModal>
  </div>
</template>

<script>
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
import TipWriter from "../../components/payments/modals/TipWriter.vue";
import OrderInfo from "../../components/order/OrderInfo.vue";
import OrdersubInfo from "../../components/order/OrdersubInfo.vue";
import WriterOrderInfo from "../../components/order/WriterOrderInfo.vue";
import EditorOrderInfo from "../../components/order/EditorOrderInfo.vue";
import OrderFiles from "../../components/order/OrderFiles.vue";
import OrderMessages from "../../components/order/OrderMessages.vue";
import UpdateOrderStatus from "../../components/order/modals/UpdateOrderStatus.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import FineComponent from "../../components/payments/FineComponent.vue";
import DueCounter from "../../components/order/DueCounter.vue";
import http from "../../modules/http.index";

// import Rating from "../../components/order/Rating.vue";
export default {
  name: "Order",
  components: {
    OrderInfo,
    WriterOrderInfo,
    EditorOrderInfo,
    OrderFiles,
    OrderMessages,
    // Rating,
    UpdateOrderStatus,
    WalletTopup,
    TipWriter,
    FineComponent,
    DueCounter,
    OrdersubInfo
  },
  computed: {
    ...mapState("order", ["order"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("auth", ["user"])


  },
  data: () => ({
    order_action: {},
    deadline: false,
    writers:[]
  }),
  async mounted() {
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id).catch((err) => {
      console.log("err.response", err.response);
      if (err && err.response && err.response.status == 404) {
        this.$router.push({ name: "Home404" });
      }
    });
    this.getWriters();
    await this._getMessages(this.order.id);
    await this.getSetups();
    this.timeout(this.order.urgency_text);
    this.$store.state.loading = false;
  },
  methods: {
    ...mapActions("order", ["_getOrder"]),
    ...mapActions("setups", ["getSetups"]),
    ...mapActions("messages", ["_getMessages"]),
    ...mapMutations("order", ["unsetOrder", "changeStatus"]),

    getWriters() {
      http
        .get(`/Orders/AvailableWriters/${this.order.id}`)
        .then((res) => {
          console.log("response for writers====>", res);
          this.writers =
            res.data.Data && res.data.Data.writers ? res.data.Data.writers : [];
        })
        .catch((err) => {
          console.log("getting writers error", err);
          // this.$notify({
          //   title: "Error",
          //   text:
          //     err.response && err.response.data
          //       ? err.response.data.Message
          //       : "Error getting writers",
          //   style: "danger",
          // });
        });
    },

    timeout(payload) {
      // console.log(payload);
      var urgency = payload / 1000;
      var d1 = 24 * 60 * 60;
      var d2 = 60 * 60;

      setInterval(() => {
        urgency--;

        var days = Math.floor(urgency / d1),
          hours = Math.floor(urgency / d2) % 24,
          minutes = Math.floor(urgency / 60) % 60,
          seconds = Math.floor(urgency / 1) % 60;

        this.deadline = `${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`;
      }, 1000);
    },
  },

  beforeDestroy() {
    this.unsetOrder();
  },
};
</script>

<style scoped lang="scss">
.nav-tabs {
  margin-bottom: 0px !important;
}
.order-title {
  max-height: 32px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .is-order-card {
    min-height: 74vh;
  }
}

.is-animated {
  transition: 0.3s ease-in-out;
}
.nav-tabs a.nav-link {
  padding: 5px 9px;
}
</style>